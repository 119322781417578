import logo from '../../images/simhill-comingsoon-logo.png'
import '../../styles/home.css'

function Home() {
    return (
      <div className="Home__outerContainer">
        <div className="Home__innerContainer">
          <img src={logo} className="Home__logo" alt="Simerson Hill Logo" />
        </div>
      </div>
    );
  }
  
  export default Home;