import '../styles/App.css';
import Home from './Home/Home.js';
import Temp from './Temp/Temp.js';
import breakpointSet from '../misc/breakpoints';
import { useState } from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
}
from 'react-router-dom';
import menuIcon from "../images/bars-solid.svg";
import React from 'react';




function App() {

  const [menuToggle, setMenuToggle] = useState(false)
  const [windowSize, setWindowSize] = useState(window.screen.width)

  window.addEventListener("resize", updateWindowSize)

  function updateWindowSize() {
    setWindowSize(window.screen.width)
  }

  function ToggleMenu() {
      setMenuToggle(!menuToggle)
      console.log(menuToggle);
  }

  var isMobile = (windowSize < breakpointSet.mobileBreakpoint)

  return (
    <Router>
      <div className="App__container">
        <nav className="Nav__container">
          <ul className={"LinkList__container " + (isMobile ? "MobileLinkList__container" : "DesktopLinkList__container") + (menuToggle || !isMobile ? "" : " MobileLinkListHidden__container")}>
            <li className="HomeLink__container">
                <Link to="/" className="HomeLink__link">Home</Link>
              </li>
              <li className="ShowsLink__container">
                <Link to="/shows">Upcoming Shows</Link>
              </li>
              <li className="BookingsLink__container">
                <a href="https://www.sonicbids.com/band/simerson-hill/?epk_fm=simerson-hill">Book Our Band</a>
              </li>
              <li className="AboutLink__container">
                <Link to="/about">About Us</Link>
              </li>
              <li className="ShopLink__container">
                <Link to="/shop">Shop</Link>
              </li>
            </ul>
          <img src={menuIcon} onClick={ToggleMenu} className={isMobile ? "App__menuIcon" : "App__menuIconHidden"} alt="Menu Icon" />
        </nav>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/shows" element={<Temp />} />
          <Route path="/about" element={<Temp />} />
          <Route path="/shop" element={<Temp />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App;
