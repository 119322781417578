import "../../styles/socialMediaIcon.css"

const SocialMediaIcon = function ({icon, iconLink}) {
    return (
        <div className="SocialMediaIcon__container">
            <a href={iconLink} className="SocialMediaIcon__link">
                <img src={icon} className="SocialMediaIcon__image" />
            </a>
        </div>
    )
}

export default SocialMediaIcon