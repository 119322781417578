import logo from '../../images/simhill-logo.png'
import facebook from '../../images/Facebook.png'
import instagram from '../../images/Instagram.png'
import twitter from '../../images/Twitter.png'
import tiktok from '../../images/Tiktok.png'
import '../../styles/home.css'
import SocialMediaIcon from "../SocialMediaIcon/SocialMediaIcon"

function Home() {
    return (
      <div className="Home__outerContainer">
        <div className="Home__innerContainer">
          <img src={logo} className="Home__logo" alt="Simerson Hill Logo" />

          <div className="Home__socialMediaIcons">
            <SocialMediaIcon icon={instagram} iconLink="https://instagram.com/simersonhill" />
            <SocialMediaIcon icon={twitter} iconLink="https://twitter.com/BandSimerson" />
            <SocialMediaIcon icon={facebook} iconLink="https://www.facebook.com/simersonhill" />
            <SocialMediaIcon icon={tiktok} iconLink="https://www.tiktok.com/@simersonhillband" />
          </div>
        </div>
      </div>
    );
  }

  export default Home;
